<template>
  <div class="auth-detail-item font-color-base">
    <div class="hidden-sm visible-md">
      <span class="list-item m-right-sm">
        <span class="font-color-sub">{{$t('music.labelUseScene')}}</span>&nbsp;
        <span>{{data.auth_scene | toAuthScene}}</span>
      </span>
      <span class="list-item m-right-sm">
        <span class="font-color-sub">{{$t('music.labelUseMethod')}}</span>&nbsp;
        <span>{{data.use_mode}}</span>
      </span>
      <span class="list-item m-right-sm">
        <span class="font-color-sub">{{$t('music.labelAuthArea')}}</span>&nbsp;
        <span>{{data.auth_area | toArea}}</span>
      </span>
      <span class="list-item m-right-sm">
        <span class="font-color-sub">{{$t('music.labelAuthTime')}}</span>&nbsp;
        <span>{{data.auth_time | toAuthPeriod}}</span>
      </span>
    </div>
    <!-- mobile -->
    <div class="visible-sm hidden-md">
      <span>{{data.auth_scene | toAuthScene}}；</span>
      <span>{{data.use_mode}}；</span>
      <span>{{data.auth_area | toArea}}；</span>
      <span>{{data.auth_time | toAuthPeriod}}；</span>
    </div>
  </div>
</template>

<script>
  import {AuthAreaOptions, BusinessSceneOptions, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {

      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toAuthPeriod(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  $height-img: 90px;

  @media screen and (min-width: $screen-width-md) {
    .auth-detail-item {
      .list-item {
        display: block;
      }
    }
  }

  @media screen and (max-width: $screen-width-md) {
    // .cart-detail-item {
    //   .item-left {
    //     width: 63px;
    //     height: 63px;
    //   }

    //   .item-right {
    //     flex-direction: column;
    //     padding-left: 75px;
    //   }
    // }
  }
</style>
