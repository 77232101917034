<template>
  <ng-dialog
    class="dialog-shop-cart"
    :visible.sync="show"
    :maskClosable="false"
    :bodyStyle="newbodyStyle"
  >
    <template v-slot:title>
      <div class="header flex">
        <!--<span class="play-icon m-right-xs music-play-icon-box">
          <music-play-icon :scope="musicInfo"></music-play-icon>
        </span>
        <div>
          <b>{{data.music_name}}</b>
          <div class="font-base">{{data.suplr_name}}</div>
        </div>-->
        <div><span style="font-size: 20px;font-weight: 500;color: #333333;">修改<span style="color:#FF7B13;">{{data.music_name}}</span>的授权信息</span></div>
      </div>
    </template>

    <cart-detail
      :data="getCartDetailData(data)"
      :show="show"
      ref="cartDeail"
      :is-edit="isEdit"
      @changeFinished="onChangeFinished"
      @changePrice="onChangePrice"
      @changeDiscount="onChangeDiscount"
    ></cart-detail>

    <template slot="footer">
      <div class="flex cen-space w-100">
        <div class="f-1">
          <template v-if="allFieldFinised">
            <h2 v-if="!hasDiscount" class="f-l font-bold">价格 ￥{{allPrice | toPrice}}</h2>
            <div v-else class="f-l">
              <a-tag class="m-empty" color="black">{{div(discountRatio, 10)}} 折</a-tag>
              <h2 class="font-bold m-empty">价格 ￥{{mul(allPrice, div(discountRatio, 100)) | toPrice}}</h2>
              <span class="text-line-through">原价 ￥{{allPrice | toPrice}}</span>
            </div>
          </template>
        </div>

        <a-button
          class="f-r"
          type="primary"
          size="large"
          @click="handleOk(false)"
          :loading="confirmLoading"
        >确认修改</a-button>
      </div>
    </template>
  </ng-dialog>
</template>

<script>
  import {Tag} from 'ant-design-vue';
  import NgDialog from '@/components/NgDialog.vue'
  import CartDetail from '@/components/CartDetail.vue'
  import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'
  import {dialogMixins} from '@/mixins/index';
  import {mul, div} from '@/utils/from-common';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        loading: false,
        musicInfo: {},
        allPrice: '',
        submitingCart: false,
        allFieldFinised: false,
        hasDiscount: false,
        discountRatio: '',
        newbodyStyle: {
          maxHeight: '600px',
        }
      }
    },

    components: {
      CartDetail,
      NgDialog,
      MusicPlayIcon,
      ATag: Tag
    },

    props: {
      data: Object,
      isEdit: Boolean,
    },

    watch: {
      data: {
        handler(newVal) {
          this.getMusicInfo(newVal.music_id);
        },
        immediate: true
      }
    },

    mixins: [dialogMixins],

    methods: {
      mul, div,

      getMusicInfo(music_id) {
        if (!music_id) return;

        this.$axios.Music_Info({music_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.musicInfo = data.data ? data.data[0] : {};
          }
        })
      },

      onChangeFinished(bool) {
        this.allFieldFinised = bool;
      },

      onChangePrice(price) {
        this.allPrice = price;
      },

      onChangeDiscount(scope) {
        this.hasDiscount = scope.discount_id;

        if (this.hasDiscount) {
          this.discountRatio = scope.ratio;
        }
      },

      getCartDetailData(data = {}) {
        let {playlist_id} = data;

        if (playlist_id) {
          return Object.assign(data, {sid: playlist_id, isSongList: true})
        }

        return data;
      },

      handleOk() {
        let cartDeail = this.$refs.cartDeail;

        if (!cartDeail) return;

        cartDeail.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            values.goods_id = this.data.goods_id

            this.editCart(values);
          }
        });
      },

      editCart(params) {
        this.confirmLoading = true;

        this.$axios.EditCart(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('cart.resMessageEditSuccess'));
            this.showCartDialog(false);
            this.$emit('success', data)
          }

          this.confirmLoading = false;
        })
      },

      showCartDialog(bool) {
        this.$emit('update:visible', bool)
      },

      handleCancel() {
        this.showCartDialog(false);
      },

      closeModal() {
        this.showCartDialog(false);
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
      .ant-modal{
        top: 50px;
      }
      .ant-modal-header {
        position: initial !important;
        padding-bottom: 8px !important;
      }
      .ant-modal-close{
        right: 0 !important;
      }
      .ant-modal-body{
        padding:12px;
      }
      .ant-form-item{
        margin-bottom:5px;
      }
  }
  .dialog-shop-cart {
    .header {
      .play-icon {
        width: 32px;
        height: 32px;
        margin-top: 6px;
      }
    }
  }
</style>
