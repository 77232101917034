<template>
  <div class="cart-box m-spill">
    <div class="cart-top p-top-md m-bottom-base">
      <div class="container">
        <h1 class="title font-sup-lg" style="font-size: 22px;font-weight: 500;color: #333333;">{{$t('cart.cartTitle')}}</h1>
      </div>
    </div>

    <div class="container"  style="min-height: 700px;">
      <ng-table 
      
        class="cart-table table-custome-space"
        :columns="columns"
        :dataSource="list"
        :rowKey="(scope) => scope.goods_id"
        :rowSelection="{
          columnWidth: 40,
          onChange: changeIds,
          selectedRowKeys: selectedRowsId,
        }"
        :pagination="false"
        :loading="loading"
        data-index="goods_id"
        ref="cartTable"
      >
        <!-- mobile -->
        <template slot="only_mobile" slot-scope="text, scope">
          <div class="hidden-md">
            <music-detail-item :data="scope" can-play>
              <template v-if="scope.discount_id">
                <b
                  v-if="scope.price"
                  class="font-lg m-bottom-xs m-top-sm inline-block m-right-sm"
                >￥{{scope.price | toPrice}}</b>
                <span
                  class="text-line-through"
                >￥{{div(scope.price, div(scope.discount_ratio, 100)) | toPrice}}</span>

                <div class="m-bottom-sm">
                  <a-tag class="m-empty" color="black">{{div(scope.discount_ratio, 10)}} 折</a-tag>
                </div>
              </template>
              <template v-else>
                <b
                  v-if="text"
                  class="font-lg m-bottom-sm m-top-sm inline-block"
                >￥{{parseFloat(text).toFixed(2)}}</b>
              </template>

              <!-- <b
                class="font-lg m-bottom-sm m-top-sm inline-block"
              >￥{{parseFloat(scope.price).toFixed(2)}}</b>-->

              <div>
                <a-button class="m-right-sm p-empty" type="link" @click="changeCart(scope)">修改授权信息</a-button>
                <a-button
                  class="font-grey p-empty"
                  type="link"
                  @click="delCart(scope)"
                >{{$t('common.del')}}</a-button>
              </div>
            </music-detail-item>
          </div>
        </template>

        <template slot="goods_detail" slot-scope="text, scope">
          <div class="flex cen-start goods-detail-col">
            <div class="img-box">
              <div class="img-all-box">
                <ng-image class="img" :src="scope.logo_url"></ng-image>
                <music-play-icon is-mask :scope="scope" :list="list"></music-play-icon>
              </div>
            </div>

            <div class="main-content-text flex flex-column">
              <span class="font-bold m-bottom-xs font-md ellipsis block">{{scope.music_name}}</span>
              <span>{{scope.suplr_name}}</span>
            </div>
          </div>
        </template>

        <template slot="auth_info" slot-scope="text, scope">
          <music-auth-detail-item :data="scope"></music-auth-detail-item>
        </template>

        <template slot="price" slot-scope="text, scope">
          <template v-if="scope.discount_id">
            <a-tag class="m-empty" color="black">{{div(scope.discount_ratio, 10)}} 折</a-tag>
            <b v-if="text" class="block price-text">￥{{text | toPrice}}</b>
            <span class="text-line-through">￥{{div(text, div(scope.discount_ratio, 100)) | toPrice}}</span>
          </template>
          <template v-else>
            <b v-if="text" style="color:#E30D0D;font-size: 16px;font-weight: 400;">￥{{parseFloat(text).toFixed(2)}}</b>
          </template>
        </template>

        <template slot="operation" slot-scope="text, scope">
          <a-button style="color: #666666;" class="m-right-sm p-empty" type="link" @click="changeCart(scope)">修改授权信息</a-button><br/>
          <a-button
            style="color: #666666;" 
            class="font-grey p-empty"
            type="link"
            @click="delCart(scope)"
          >{{$t('common.del')}}</a-button>
        </template>
      </ng-table>

      <a-row class="cart-middle" v-if="allPrice > 0">
        <a-col :xs="24" :sm="24" :md="19">
          <a-checkbox class="m-bottom-sm" v-model="didAgreement">
            <i18n path="form.iReadAndAgree" tag="span">
              <span
                class="font-color-base"
                v-t="'form.musicBuyAgreement'"
                @click.prevent="showBuyNotice = true"
              ></span>
            </i18n>
          </a-checkbox>
          <div style="font-size: 12px;font-weight: 400;color: rgba(0, 0, 0, 0.55);">购买后可以提供专业授权证书、开具发票、申请纸质授权合同，提供授权证书,可以通过SSM授权查询功能查询使用授权。
            <router-link :to="`/authCenter?license_id=default`" tag="a" target="_blank">查看授权书示例</router-link>
          </div>
        </a-col>
         <a-col :xs="4" :sm="4" :md="5">
          <div class="coupon-select-wrap flex row cen-end">
              <a-select
              allowClear
              v-model="coupon_code_id"
              style="width:100%"
              :placeholder="placeholder"
              size="large"
              @change="changeCoupon"
            >
            <a-select-option v-for="(item,i) in CouponOptions" :key="i" :value="item.id">
              {{ item.coupon_name }}
            </a-select-option>
            </a-select>
          </div>
          </a-col>
        <a-col :xs="24" :sm="24" :md="8"></a-col>
      </a-row>

      <a-row class="bottom-submit" v-if="allPrice > 0" style="border: 1px solid #EBF0F2;padding-left:21px;">
        <a-col class="price-box flex cen-space" :xs="24" :sm="24" :md="18">
          <span class="left f-l">
            已选
            <span class="font-color-primary">{{selectedNum}}</span>
            首
          </span>
          <div class="right f-r text-right">
            <div class="price">
              <span class="m-right-xs">合计：</span>
              <span style="color:#E30D0D;font-size:24px;">￥{{parseFloat(useCouponPrice || allPrice).toFixed(2)}}</span>
            </div>
              <span class="m-right-xs font-color-sub">订单金额 ¥{{parseFloat(allPrice).toFixed(2)}}</span>
              <span class="font-color-sub">优惠金额 ¥{{useCouponPrice == 0?0: parseFloat(allPrice - useCouponPrice ).toFixed(2)}}</span>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="6">
          <a-button
            style="background: #FF7B13;border-radius: 4px;border: 1px solid #FF7B13;font-size:16px;"
            class="submit-btn w-100"
            type="primary"
            @click="submitOrder"
          >立即购买</a-button>
        </a-col>
      </a-row>
    </div>

    <dialog-edit-cart
      :visible.sync="showEditCartDialog"
      :data="editCartMusicInfo"
      :is-edit="isEdit"
      @success="onSuccess"
    ></dialog-edit-cart>

    <dialog-buy-notice :visible.sync="showBuyNotice"></dialog-buy-notice>
  </div>
</template>

<script>
  import {Tag} from 'ant-design-vue';
  import MusicDetailItem from '@/pages/components/music/MusicDetailItem.vue'
  import MusicAuthDetailItem from '@/pages/components/music/MusicAuthDetailItem.vue'
  import DialogEditCart from './components/DialogEditCart.vue'
  import DialogBuyNotice from './components/DialogBuyNotice.vue'
  import NgTable from '@/components/NgTable.vue'
  import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'
  import * as TabCol from '@/config/columns';
  import {UpdateMusicList} from '@/mixins/music';
  import {add, mul, div} from '@/utils/from-common';

  export default {
    data() {
      const columns = [
        TabCol.commonKey('', 'only_mobile', {isSlot: true, onlyMobile: true}),
        TabCol.commonKey(this.$t('goods.info'), 'goods_detail', {isSlot: true, ellipsis: true, verticalAlign: 'center', className: 'good-detail', onlyPc: true}),
        TabCol.commonKey(this.$t('goods.authInfo'), 'auth_info', {isSlot: true, onlyPc: true}),
        TabCol.commonKey(this.$t('goods.price'), 'price', {width: 200, isSlot: true, align: 'center', onlyPc: true}),
        TabCol.commonKey(this.$t('template.operation'), 'operation', {width: 200, isSlot: true, align: 'center', onlyPc: true}),
      ]

      return {
        columns,
        list: [],
        rowSelection: {},

        loading: false,

        allPrice: 0,
        useCouponPrice: 0,

        selectedNum: 0,
        selectedScope: [],

        selectedRowsId: [],

        selectCoupon:undefined,
        coupon_code_id:undefined,


        didAgreement: true,

        showBuyNotice: false,
        showEditCartDialog: false,
        editCartMusicInfo: {},
        isEdit: false,
        CouponOptions:[]
      }
    },

    components: {
      MusicDetailItem,
      MusicAuthDetailItem,
      // AAffix: Affix
      DialogEditCart,
      DialogBuyNotice,
      NgTable,
      MusicPlayIcon,
      ATag: Tag
    },

    computed: {
      canSubmit() {
        return this.selectedScope && this.selectedScope.length > 0 && this.didAgreement
      },
      placeholder(){
        return this.CouponOptions.length>0?'请选择优惠券':'暂无可用优惠券'
      }
    },

    mixins: [UpdateMusicList],

    watch: {
      selectedRowsId: {
        handler(newVal = []) {
          let scopes = this.list.filter(item => ~newVal.indexOf(item.goods_id));

          this.calcNumAndCost(scopes);
          if(this.coupon_code_id){
            if(this.coupon_code_id!=='none'){
              this.$message.error('商品发生变化,请重新选择优惠券');
               this.coupon_code_id = undefined
            }else{
              this.coupon_code_id = 'none'
            }
              this.useCouponPrice = 0;
              this.selectCoupon = undefined
           
          }
          this.GetCoupon()
        }
      },
      changeData(newVal) {
        if (newVal && newVal.length > 0) {
          let list = this.list;

          newVal.forEach(item => {
            list = this.onUpdate(list, item);
          })

          this.list = list;
        }
      }
    },

    created() {
      this.selectedRowsArr = [];
      this.getList();
    },

    methods: {
      mul, div,
      getList() {
        this.loading = true;

        let params = {page_size: 100};

        return new Promise(resolve => {
          this.$axios.CartList(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.list = data.data.list ? data.data.list : []

              this.$nextTick(() => {
                this.selectedRowsId = this.list.map(item => item.goods_id);
               

              })

              resolve(data.data);
            }

            this.loading = false;
          })
        })
      },

      changeIds(newVal) {
        this.selectedRowsId = newVal;
      },

      submitOrder() {
        let selectedScope = this.selectedScope;

        if (!(selectedScope && selectedScope.length > 0)) {
          this.$message.error(this.$t('goods.selectGoods'));
          return false;
        }

        if (!this.didAgreement) {
          this.$message.error(this.$t('cart.reqMessageRead'));
          return false;
        }

        let goods_ids = selectedScope.map(item => item.goods_id)

        let params = {
          goods_ids,
          coupon_id:this.selectCoupon,
          coupon_code_id:this.coupon_code_id=='none'?'':this.coupon_code_id
        }

        this.$store.commit('changeContentLoading', true);

        this.$axios.AddToOrder(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let orderDetail = data.data

            this.$router.push(`/paymusic/${orderDetail.order_id}`);

            this.$store.dispatch('updateGoodNumOfCart');
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      },

    

      changeCart(scope) {
        scope = {...scope};
        scope.name = scope.music_name;

        this.editCartMusicInfo = scope;
        this.isEdit = true;
        this.showEditCartDialog = true;

      },
      GetCoupon(){
        let goods_id = this.selectedRowsId
        this.$axios.GetCouponForCart({goods_id:goods_id,source:1}).then(res=>{
          const data = res.data;
          if(data.code == 0){
            this.CouponOptions =data.data
            if(this.CouponOptions.length){
                this.CouponOptions.unshift({
                  coupon_name:'不使用优惠券',
                  id:'none'
                })
            }
          }

        })
      },
      changeCoupon(e){
        console.log('e: ', e);
  
        if(!e ){
          this.coupon_code_id = undefined
          this.selectCoupon = undefined
          this.useCouponPrice =0
          return
        }
       
         let activeCoupon =  this.CouponOptions.find(item =>item.id == this.coupon_code_id)
         console.log('activeCoupon: ', activeCoupon);
         this.selectCoupon = activeCoupon.coupon_id
        // this.allPrice = 
        if(activeCoupon.coupon_type == 1){
          // 折扣券
          this.useCouponPrice = this.allPrice * (activeCoupon.discount*0.1 )
        }else{
          // 满减券
          console.log('满减券: ', '满减券');
          console.log('this.useCouponPrice: ', this.useCouponPrice);
          if(e == 'none'){
              this.useCouponPrice =0
          }else{
              this.useCouponPrice = add(this.allPrice ,-activeCoupon.money )

          }
        }
      },

      delCart(scope = {}) {
        let that = this;

        this.$confirm({
          width: '480px',
          title: this.$t('cart.reqMessageConfirmDelMusic'),
          // content: '删除该角色，关联该角色的成员账号，会失去对应的权限；',
          onOk() {
            return new Promise((resolve, reject) => {
              that.reqDel(scope, resolve, reject);
            });
          }
        });
      },

      reqDel(scope, next, reject) {
        let params = {goods_id: scope.goods_id || ''}

        this.$axios.DelCart(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('cart.resMessageDelMusicSuccess'));
            this.selectedRowsArr = this.selectedRowsArr.filter(item => item.goods_id !== scope.goods_id);
            this.calcNumAndCost(this.selectedRowsArr);
            next();
            this.getList();
            this.$store.dispatch('updateGoodNumOfCart');
          } else {
            reject()
          }
        })
      },

      calcNumAndCost(selectedRow) {
        if (selectedRow && selectedRow.length >= 0) {
          this.selectedScope = selectedRow;
          this.selectedNum = selectedRow.length;

          this.allPrice = selectedRow.reduce((prev, next) => {
            return add(prev, `${next.price || 0}`);
          }, '0')
        }
      },

      onSuccess() {
        this.getList().then((data) => {
          let list = data ? data.list : [];
          let goods_ids = this.selectedRowsArr.map(item => item.goods_id);

          this.selectedRowsArr = list.filter(item => goods_ids.includes(item.goods_id));

          this.calcNumAndCost(this.selectedRowsArr);
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-table-thead {
      .good-detail {
        padding-left: 40px !important;
      }

      > tr > th {
        background-color: #F8FAFB;
      }
    }

    .ant-table-tbody > tr > td {
      white-space: normal;
    }

    .ant-table-selection-column {
      .ant-checkbox + span {
        position: absolute;
      }
    }
    .ant-table-placeholder{
      border: none;
    }
  }

  .cart-box {
    .cart-table {
      margin-top: 36px;
      /* min-height:700px; */
    }

    .price-text {
      // line-height: 2;
      margin-top: 5px;
    }

    .cart-middle {
      padding: 24px 0 32px;
      position: relative;
    }

    .bottom-submit {
      background-color: #ffffff;

      .price-box {
        height: 72px;
      }

      .submit-btn {
        height: 72px;
        line-height: 72px;
      }
    }

    .goods-detail-col {
      .img-box {
        width: 90px;
        height: 90px;
        margin-right: 12px;
        flex-shrink: 0;
      }
    }
  }
  .coupon-select-wrap {
      width: 100%;
  }
  @media screen and (min-width: $screen-width-md) {
    .price-box {
      padding-right: 12px;
    }

    .main-content-text {
      width: 110px;
    }
   
  }

  @media screen and (min-width: $screen-width-xl) {
    .main-content-text {
      width: 220px;
    }
  }

  @media screen and (max-width: $screen-width-md) {
     .coupon-select-wrap {
      width: 200px;
      margin-top: 16px;
    }
    .bottom-submit {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $border-color;
      z-index: 100;

      .price-box {
        padding: 0 3%;
        margin-bottom: 10px;
      }
    }

    .cart-box {
      padding-bottom: 140px;
    }

    .cart-middle {
      border-bottom: none;
    }
  }
</style>
